<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/vendo-conteudo-pelo-clozzy-e-preciso-de-ajuda">Vendo conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Como configurar sua conta</span></p>
    <div>
      <section>
        <h2>Como configurar sua conta</h2>
        <h3 class="explanation">Confira o passo a passo para começar a vender no Clozzy</h3>
        <h3>Primeiros passos para habilitar sua conta:</h3>
        <li>Acesse o site <a target="_blank" rel="external" href="https://clozzy.com.br/">www.clozzy.com.br</a></li>
        <li>No canto superior direito, clique em <strong>Cadastrar</strong></li>
        <li>Preencha o formulário que entraremos em contato, e enviaremos seu acesso para criar sua conta.</li>
        <h3>Configurando sua conta</h3>
        <p>Depois de passar pela etapa de ativação dessa funcionalidade, é o momento de configura-la. Para isso você pode seguir os passos abaixo:</p>
        <li><strong>Dados cadastrais</strong>: Você será redirecionado a um formulário para preencher os dados de sua conta. Preencha as informações no formulário e clique em Continuar.</li>
        <li><strong>Acesso à conta</strong>: Após o preenchimento do formulário, você terá acesso a sua conta de criador de conteúdo. Você pode trocar sua foto de perfil, configurar meios de pagamento e postar seus conteúdos.</li>
        <h3>Personalizando sua conta</h3>
        <li><strong>Editar Perfil</strong>: Na página inicial, clique em Editar Perfil para trocar sua foto de perfil e seu nome. Nessa tela também há o link de acesso para você divulgar aos seus seguidores</li>
        <li><strong>Planos de assinatura</strong>: Na tela inicial, clique no ícone de Menu, no canto superior direito da tela. Vá em Meus Planos e configure o valor dos seus planos mensais, trimestrais e semestrais. Todos os campos devem ser preenchidos.</li>
        <li><strong>Meios de pagamento</strong>: Na tela inicial, clique no ícone de Menu, no canto superior direito da tela. Vá em Chave Pix e preencha seus dados bancários. É para essa conta que o valor das assinaturas será redirecionado.</li>
        <h3>Divulgando sua conta</h3>
        <li>Para iniciar as vendas, copie o link do seu perfil que está localizado no botão Editar Perfil na tela inicial e cole nas suas redes sociais</li>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>
    </div>
  </article>


