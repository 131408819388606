<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/comprei-pelo-clozzy-e-preciso-de-ajuda">Comprei conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Como cancelar a assinatura</span></p>
    <div>
      <section>
        <h2>Como cancelar a assinatura</h2>
        <h3 class="explanation">O passo a passo para cancelar sua assinatura rapidamente</h3>
        <p>Para cancelar sua assinatura, é necessário estar logado na sua conta.</p>
        <p>Após o login, siga os seguintes passos:</p>
        <li>Vá em <strong>Configurações</strong> no canto superior direito da tela.</li>
        <li>Nesse menu, clique em <strong>Cancelar Assinatura</strong></li>
        <li>Clique em <strong>Confirmar</strong></li>
        <p>Pronto! Sua assinatura já está cancelada.</p>
        <p>Caso queira reativar sua conta, basta fazer login e senha novamente na tela de cadastro do influencer de sua preferência.</p>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>
    </div>
  </article>


