import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-follower-case3',
  templateUrl: './follower-case3.component.html',
  styleUrls: ['../../../../style-services/style-cases.css']
})
export class FollowerCase3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
