<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/comprei-pelo-clozzy-e-preciso-de-ajuda">Comprei conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Perguntas frequentes</span></p>
    <div>
      <section>
        <h2>Perguntas frequentes</h2>
        <h3 class="explanation">As perguntas mais feitas pelos usuários do Clozzy</h3>
        <li><strong>Problema no envio do comprovante</strong>: Caso o comprovante não seja aceito de imediato, sugerimos acessar o link da influencer por outro navegador. Pode ser que o seu navegador esteja desatualizado. Lembramos de sempre enviar o print do comprovante, e nunca o PDF. O envio do comprovante NÃO pode ser feito pelo formulário de suporte, apenas pelo link da influencer. </li>
        <li><strong>Pedido de reembolso</strong>: Pedidos de reembolso devem ser solicitados diretamente para a influencer através do instagram ou telegram. Como o PIX é direto para a conta dela, só ela pode fazer essas operações. </li>
        <li><strong>Aparece pra pagar de novo</strong>: Caso você tenha pago, e no momento do seu login aparecer a tela de pagamento, significa que você fez o pagamento usando um email e agora está tentando entrar na plataforma usando outro email. Tem que usar sempre o mesmo email.</li>
        <li><strong>Cancelamento</strong>: O cancelamento pode ser feito fazendo o seu login na plataforma e clicando em "Cancelar assinatura". Caso a operação não dê certo, entre em contato com o suporte no formulário abaixo.</li>
        <li><strong>Pagamento em análise</strong>: Caso o pagamento esteja sob análise do influencer, sugerimos que envie uma mensagem pelo instagram dele para agilizar o processo. Como é preciso aprovar os comprovantes manualmente, pode demorar um pouco. Frisamos que apenas o influencer pode aprovar seu pagamento, pois é um conteúdo privado dele.</li>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>    </div>
  </article>


