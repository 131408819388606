<div class="geral">
  <header>
    <app-header></app-header>
  </header>
  <article>
    <router-outlet>
    </router-outlet>
  </article>
  <footer class="footer">
    <app-footer></app-footer>
  </footer>
</div>




