<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/vendo-conteudo-pelo-clozzy-e-preciso-de-ajuda">Vendo conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Chargeback VS Reembolso: quais as diferenças</span></p>
    <div>
      <section>
        <h2>Chargeback VS Reembolso: quais as diferenças</h2>
        <h3 class="explanation">Veja como proceder em casos de estorno do usuário</h3>
        <h3>Chargeback</h3>
        <p>O <strong>chargeback</strong> acontece quando um assinante do seu material exclusivo solicita o bloqueio do pagamento referente àquela compra diretamente com o banco dele.</p>
        <p>Isso pode ocorrer por:</p>
        <li>Irregularidade: quando o usuário se sentiu fraudado ou lesado de alguma forma;</li>
        <li>Compra não reconhecida: o comprador não reconhece a compra e solicita o cancelamento;</li>
        <li>Ou até mesmo por má-fé do assinante.</li>
        <h3>Como proceder mediante um chargeback?</h3>
        <p>Quando um usuário solicita o chargeback, o pagamento é bloqueado em sua operadora de cartão e, consequentemente, no Clozzy. Assim, todo o valor daquela venda também é bloqueado.</p>
        <p>Como informa nos Termos de Uso, o usuário está ciente de que em caso de chargeback por parte do cliente, o Clozzy acatará o reembolso, não cabendo ao usuário agir nestes casos.</p>
        <h3>Reembolso</h3>
        <p>Já o reembolso não é um cancelamento de compra feito pela operadora do cartão, mas uma devolução do dinheiro gasto pelo cliente.</p>
        <p>Está ação é manual e pode ser feita apenas pelo dono do produto ou serviço vendido.</p>
        <h3>Como se posicionar diante uma situação de reembolso?</h3>
        <p>É de responsabilidade do usuário realizar a devolução do pagamento ao usuário caso a solicitação seja considerada válida e amparada legalmente. </p>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>    </div>
  </article>


