<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/comprei-pelo-clozzy-e-preciso-de-ajuda">Comprei conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Como solicitar o reembolso</span></p>
    <div>
      <section>
        <h2>Como solicitar o reembolso</h2>
        <h3 class="explanation">Entenda nossa política de reembolso e como fazer uma solicitação.</h3>
        <p>Para solicitar o reembolso, você deve entrar em contato com o seu influencer através dos canais de comunicação dele. Como o valor cai diretamente na conta dele, só ele pode realizar o estorno.</p>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>    </div>
  </article>


