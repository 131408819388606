<header>
  <div class="top">
    <h1>Clozzy</h1>
    <a href="https://clozzy.com.br/" rel="external">
      <h3 class="link"><svg width="13" height="13" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="#FFF" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M11.5 6.73v6.77H.5v-11h7.615M4.5 9.5l7-7M13.5 5.5v-5h-5"/></g></svg>Acesse o Clozzy</h3>
    </a>
  </div>
    <h2>Dicas e respostas da Equipe Clozzy</h2>
</header>


