<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/vendo-conteudo-pelo-clozzy-e-preciso-de-ajuda">Vendo conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>O que é o Clozzy e como ele funciona</span></p>
    <div>
      <section>
        <h2>O que é o Clozzy e como ele funciona</h2>
        <h3 class="explanation">O básico que o influencer precisa saber para usar o Clozzy</h3>
        <p>O Clozzy é uma ferramenta para quem quer vender conteúdo exclusivo fazendo a gestão dos seus clientes de forma automática.</p>
        <p>Essa funcionalidade vai te ajudar na realização de algumas tarefas como:</p>
        <li>Adicionar e remover automaticamente usuários do seu perfil exclusivo;</li>
        <li>Realizar a cobrança de assinatura do conteúdo</li>
        <p>Você terá uma página feita para a conversão de novos assinantes, onde as pessoas interessadas em receber os seus conteúdos exclusivos irão efetuar a assinatura.</p>
        <h3>Como ele funciona?</h3>
        <p>É muito simples! Você cria seu material exclusivo na plataforma, realiza as configurações necessárias e compartilha o link para pagamento em suas redes sociais.</p>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>
    </div>
  </article>


