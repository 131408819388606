<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/comprei-pelo-clozzy-e-preciso-de-ajuda">Comprei conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Como acessar o conteúdo do influencer</span></p>
    <div>
      <section>
        <h2>Como acessar o conteúdo do influencer</h2>
        <h3 class="explanation">O melhor caminho para acessar o influencer com exclusividade.</h3>
        <p>
          O conteúdo do influencer pode ser acessado através do link personalizado que ele disponibiliza. Esse link pode estar nas suas redes sociais, como na bio do instagram ou twitter, e em grupos de whatsapp e telegram que o influencer possa ter.
        </p>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>
    </div>
  </article>


