  <article>
    <a href='/vendo-conteudo-pelo-clozzy-e-preciso-de-ajuda'>
      <div class="info">
        <svg enable-background="new 0 0 24 24" height="48px" viewBox="0 0 24 24" width="48px" fill="#585858"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z"/><polygon points="15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93"/><polygon points="3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9"/></g></g></svg>
        <div class="txt">
          <h2>Vendo conteúdo pelo Clozzy e preciso de ajuda</h2>
          <p>Ajuda para criadores de conteúdo que estão com dúvida sobre a gestão de suas comunidades e vendas.</p>
        </div>
      </div>
    </a>
    <a href='/comprei-pelo-clozzy-e-preciso-de-ajuda'>
      <div class="info">
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="48px" viewBox="0 0 24 24" width="48px" fill="#585858"><g><rect fill="none" height="24" width="24"/><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"/></g></svg>
        <div class="txt">
          <h2>Comprei pelo Clozzy e preciso de ajuda</h2>
          <p>Ajuda para quem comprou e quer saber mais sobre acesso a comunidade, pagamentos, entre outros.</p>
        </div>
      </div>
    </a>
  </article>

