<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/comprei-pelo-clozzy-e-preciso-de-ajuda">Comprei conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Problemas ao realizar a assinatura</span></p>
    <div>
      <section>
        <h2>Problemas ao realizar a assinatura</h2>
        <h3 class="explanation">Os erros mais comuns ao realizar uma assinatura Clozzy</h3>
        <p>Caso o pagamento esteja em análise por muito tempo, pedimos que entre em contato com o seu influencer através dos canais de comunicação dele. Somente ele pode liberar o seu acesso.</p>
        <p>Caso a plataforma esteja pedindo para pagar de novo, podem ter acontecido os seguintes problemas:</p>
        <li>Você está tentando logar com outro email, ou você digitou o email errado</li>
        <li>Por algum critério do influencer, o seu pagamento foi recusado. Neste caso, pedimos que envie novamente o comprovante</li>
        <p>E não se preocupe, os seus dias de assinatura só começam a contar a partir do dia que você recebeu acesso. Você será avisado por email.</p>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>    </div>
  </article>


