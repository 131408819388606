import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-influencer-case1',
  templateUrl: './influencer-case1.component.html',
  styleUrls: ['../../../../style-services/style-cases.css']
})
export class InfluencerCase1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
