<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/vendo-conteudo-pelo-clozzy-e-preciso-de-ajuda">Vendo conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Perguntas frequentes</span></p>
    <div>
      <section>
        <h2>Perguntas frequentes</h2>
        <h3 class="explanation">Confira as perguntas mais feitas pelos influencers do Clozzy</h3>
        <h3>Como posso organizar meu conteúdo?</h3>
        <p>Recomendamos que você deixe alguns stories fixados no Instagram com previews do seu conteúdo pago para atrair novos usuários.</p>
        <h3>Quais os requisitos necessários para vender no Clozzy</h3>
        <p>O Clozzy funciona pelo método de avaliação individual do perfil. Ou seja, ao solicitar o seu cadastro, o Clozzy avaliará se o seu perfil está condizente com a cultura da empresa e se está dentro dos números mínimos de alcance e engajamento da sua conta.</p>
        <h3>Como o usuário vai encontrar meu conteúdo</h3>
        <p>Através das suas redes sociais. O Clozzy disponibiliza um link personalizado para seus seguidores acessarem seu conteúdo exclusivo. Você pode colocar na sua bio do instagram, do twitter, ou divulgar em grupos de whatsapp e telegram. Clicando nesse link, o usuário cadastrado e com assinatura ativa terá acesso através do login e senha dele. Já o usuário que não tem cadastro vai ser redirecionado para uma tela de cadastro e pagamento.</p>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>    </div>
  </article>


