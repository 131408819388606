import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-influencer-question',
  templateUrl: './influencer-question.component.html',
  styleUrls: ['../../../style-services/style-questions.css']
})

export class InfluencerQuestionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
