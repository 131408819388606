<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/vendo-conteudo-pelo-clozzy-e-preciso-de-ajuda">Vendo conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>O que significa cada status dos pagamentos</span></p>
    <div>
      <section>
        <h2>O que significa cada status dos pagamentos</h2>
        <h3 class="explanation">Entenda as diferenças de status de pagamento dos seus assinantes.</h3>
        <p>Os pagamentos têm diferentes status que auxiliam os criadores e membros a acompanhar o acesso em suas comunidades.</p>
        <h3>Pix</h3>
        <li><strong>Confirmado</strong>: Pagamentos confirmados com sucesso.</li>
        <li><strong>Pendentes</strong>: Pagamentos aguardando aprovação do comprovante pelo influencer</li>
        <!--h3>Cartão (em breve)</-h3>
        <li><strong>Chargeback</strong>: Pagamento devolvido automaticamente devido à contestação da cobrança pelo titular do cartão.</li>
        <li><strong>Reembolso</strong>: Pagamentos devolvidos por solicitação do cliente.</li><-->
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>
    </div>
  </article>


