<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas<i class="material-icons v-middle">arrow_forward_ios</i></a> </span> <span>Comprei conteúdo pelo Clozzy e preciso de ajuda</span></p>
    <div class="content">
      <div class="head">
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="48px" viewBox="0 0 24 24" width="48px" fill="#585858"><g><rect fill="none" height="24" width="24"/><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"/></g></svg>
        <h2>Comprei conteúdo pelo Clozzy e preciso de ajuda</h2>
      </div>
      <p class="subtitle">Ajuda para quem fez uma assinatura e quer saber sobre acesso à comunidade, pagamentos e mais informações.</p>

      <li class="info">Acesso</li>

      <a href='/clozzy-para-seguidores'>
        <div class="question">
            <h2 class="title">O que é o Clozzy e como ele funciona</h2>
          <p class="explanation">Conheça a melhor plataforma de conteúdo exclusivo do Brasil.</p>
        </div>
      </a>
      <a href='/como-configurar-conta-de-seguidor'>
        <div class="question">
            <h2 class="title">Como configurar sua conta</h2>
          <p class="explanation">Vamos deixar sua conta pronta pra uso!</p>
        </div>
      </a>
      <a href='/como-acessar-o-conteudo-do-influencer'>
        <div class="question">
            <h2 class="title">Como acessar o conteúdo do influencer</h2>
          <p class="explanation">O melhor caminho para acessar o influencer com exclusividade.</p>
        </div>
      </a>
      <a href='/problemas-ao-realizar-a-assinatura'>
        <div class="question">
            <h2 class="title">Problemas ao realizar a assinatura</h2>
          <p class="explanation">Os erros mais comuns ao realizar uma assinatura Clozzy</p>
        </div>
      </a>
      <a href='/como-cancelar-a-assinatura'>
        <div class="question">
            <h2 class="title">Como cancelar a assinatura</h2>
          <p class="explanation">O passo a passo para cancelar sua assinatura rapidamente</p>
        </div>
      </a>

      <li class="info">Financeiro</li>

      <a href='/cobranças-indevidas'>
        <div class="question">
            <h2 class="title">Cobranças indevidas</h2>
          <p class="explanation">Como contestar uma cobrança indevida</p>
        </div>
      </a>
      <a href='/como-solicitar-o-reembolso'>
        <div class="question">
            <h2 class="title">Como solicitar o reembolso</h2>
          <p class="explanation">Entenda nossa política de reembolso e como fazer uma solicitação.</p>
        </div>
      </a>

      <li class="info">Mais perguntas</li>

      <a href='/vantagens-em-comprar-pelo-clozzy'>
        <div class="question">
            <h2 class="title">Vantagens em usar o Clozzy</h2>
          <p class="explanation">Por quê consumir conteúdo exclusivo pelo Clozzy</p>
        </div>
      </a>
      <a href='/perguntas-frequentes-dos-seguidores'>
        <div class="question">
            <h2 class="title">Perguntas frequentes</h2>
          <p class="explanation">As perguntas mais feitas pelos usuários do Clozzy</p>
        </div>
      </a>
      <h4><a target="_blank" rel="external" href="https://docs.google.com/forms/d/e/1FAIpQLScS7soMZDBIBe0F9P9OAwso9SfRKkLqEOw8k_Z2cub8lebfTQ/viewform">Caso o seu problema não esteja listado acima, clique aqui para entrar em contato com o suporte técnico</a></h4>
    </div>
  </article>



