<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/comprei-pelo-clozzy-e-preciso-de-ajuda">Comprei conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Vantagens em usar o Clozzy</span></p>
    <div>
      <section>
        <h2>Vantagens em usar o Clozzy</h2>
        <h3 class="explanation">Por quê consumir conteúdo exclusivo pelo Clozzy</h3>
        <li>O Clozzy fornece uma plataforma segura para o usuário consumir conteúdo de forma discreta, rápida e segura</li>
        <li>O pagamento é feito por PIX, uma forma rápida e segura de fazer pagamentos.</li>
        <li>Você pode acessar o Clozzy de qualquer lugar, a qualquer momento, sem gastar muito dos seus dados.</li>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>
     </div>
  </article>


