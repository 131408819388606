<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/vendo-conteudo-pelo-clozzy-e-preciso-de-ajuda">Vendo conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Como sacar o saldo das minhas vendas</span></p>
    <div>
      <section>
        <h2>Como sacar o saldo das minhas vendas</h2>
        <h3 class="explanation">O caminho mais rápido para você resgatar o valor de suas vendas no Clozzy.</h3>
        <p>O valor das assinaturas cai diretamente na sua conta através do PIX</p>
        <p>Para cadastrar sua chave PIX do seu banco de preferência, cumpra os seguintes passos:</p>
        <li>Acesse <a href="https://meu.clozzy.com.br/auth">meu.clozzy.com.br/auth</a></li>
        <li>No canto superior direito, clique em <strong>Configurações</strong></li>
        <li>Vá na opção <strong>CHAVE PIX</strong></li>
        <li>Preencha os seus dados bancários</li>
        <p>Agora sua chave PIX está cadastrada e todos os valores recebidos pelas assinaturas caem diretamente na sua conta.</p>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>
    </div>
  </article>


