<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/vendo-conteudo-pelo-clozzy-e-preciso-de-ajuda">Vendo conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Como alterar o valor dos meus planos Clozzy</span></p>
    <div>
      <section>
        <h2>Como alterar o valor dos meus planos Clozzy</h2>
        <h3 class="explanation">Altere livremente os valores das suas assinaturas do Clozzy</h3>
        <li>Para alterar o valor dos seus planos, acesse <a href="https://meu.clozzy.com.br/auth" target="_blank" rel="external"><strong>meu.clozzy.com.br/auth</strong></a> e faça seu login.</li>
        <li>Vá em <strong>Configurações</strong>, no canto superior direito da tela.</li>
        <li>Acesse a opção <strong>Meus Planos</strong></li>
        <li>Atualize os valores cobrados pelas assinaturas.</li>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>
    </div>
  </article>


