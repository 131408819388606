<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/vendo-conteudo-pelo-clozzy-e-preciso-de-ajuda">Vendo conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Quanto custa vender pelo Clozzy</span></p>
    <div>
      <section>
        <h2>Quanto custa vender pelo Clozzy</h2>
        <h3 class="explanation">As menores taxas do mercado você encontra no Clozzy. Veja aqui quais são!</h3>
        <p>O Clozzy possui a menor taxa do mercado e cobra <strong>apenas 10%</strong>, o restante vai para o influencer.</p>
        <p>Então, se o valor cobrado pelo seu plano for de 50 reais, vendendo 100 assinaturas, seu lucro total é de R$4.500,00</p>
        <p>Para calcular o valor estimado dos seus lucros, faça a seguinte operação:</p>
        <li><strong>( </strong> Valor da mensalidade <strong>x</strong> Número de Clientes <strong> ) </strong><strong> x</strong> 0,9</li>
        <p>Confira dicas para potencializar suas vendas clicando <a href="/como-otimizar-suas-vendas"><strong>AQUI</strong></a></p>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>
    </div>
  </article>


