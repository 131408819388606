import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-follower-case5',
  templateUrl: './follower-case5.component.html',
  styleUrls: ['../../../../style-services/style-cases.css']
})
export class FollowerCase5Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
