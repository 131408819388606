<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas<i class="material-icons v-middle">arrow_forward_ios</i></a> </span> <span>Vendo conteúdo pelo Clozzy e preciso de ajuda</span></p>
    <div class="content">
      <div class="head">
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="48px" viewBox="0 0 24 24" width="48px" fill="#585858"><g><rect fill="none" height="24" width="24"/><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"/></g></svg>
        <h2>Vendo conteúdo pelo Clozzy e preciso de ajuda</h2>
      </div>
      <p class="subtitle">Ajuda para criadores de conteúdo que estão com dúvida sobre a gestão de suas comunidades e vendas.</p>

      <li class="info">Acesso</li>

      <a href='/clozzy-para-criadores-de-conteudo'>
        <div class="question">
            <h2 class="title">O que é o Clozzy e como ele funciona</h2>
          <p class="explanation">O básico que o influencer precisa saber para usar o Clozzy</p>
        </div>
      </a>
      <a href='/configurar-conta-de-criador-de-conteudo'>
        <div class="question">
            <h2 class="title">Como configurar sua conta</h2>
          <p class="explanation">Confira o passo a passo para começar a vender no Clozzy</p>
        </div>
      </a>

      <li class="info">Financeiro</li>

      <a href='/vantagens-em-vender-pelo-clozzy'>
        <div class="question">
            <h2 class="title">Vantagens em usar o Clozzy</h2>
          <p class="explanation">Por que o Clozzy é a plataforma ideal para vender conteúdos exclusivos</p>
        </div>
      </a>
      <a href='/como-alterar-o-valor-dos-meus-planos-clozzy'>
        <div class="question">
            <h2 class="title">Como alterar o valor dos meus planos Clozzy</h2>
          <p class="explanation">Altere livremente os valores das suas assinaturas do Clozzy</p>
        </div>
      </a>
      <a href='/quanto-custa-vender-pelo-clozzy'>
        <div class="question">
            <h2 class="title">Quanto custa vender pelo Clozzy</h2>
          <p class="explanation">As menores taxas do mercado você encontra no Clozzy. Veja aqui quais são!</p>
        </div>
      </a>
      <a href='/como-sacar-o-saldo-das-minhas-vendas'>
        <div class="question">
            <h2 class="title">Como sacar o saldo das minhas vendas</h2>
          <p class="explanation">O caminho mais rápido para você resgatar o valor de suas vendas no Clozzy.</p>
        </div>
      </a>
      <a href='/o-que-significa-cada-status-dos-pagamentos'>
        <div class="question">
            <h2 class="title">O que significa cada status dos pagamentos</h2>
          <p class="explanation">Entenda as diferenças de status de pagamento dos seus assinantes.</p>
        </div>
      </a>
      <a href='/chargeback-vs-reembolso-quais-as-diferencas'>
        <div class="question">
            <h2 class="title">Chargeback VS Reembolso: quais as diferenças</h2>
          <p class="explanation">Veja como proceder em casos de estorno do usuário</p>
        </div>
      </a>
      <a href='/o-que-significa-cada-status-das-assinaturas'>
        <div class="question">
            <h2 class="title">O que significa cada status das assinaturas</h2>
          <p class="explanation">Entenda as diferenças de status das assinaturas dos seus assinantes.</p>
        </div>
      </a>

      <li class="info">Mais perguntas</li>

      <a href='/perguntas-frequentes-dos-criadores-de-conteudo'>
        <div class="question">
            <h2 class="title">Perguntas frequentes</h2>
          <p class="explanation">Confira as perguntas mais feitas pelos influencers do Clozzy</p>
        </div>
      </a>
      <h4><a target="_blank" rel="external" href="https://docs.google.com/forms/d/e/1FAIpQLScS7soMZDBIBe0F9P9OAwso9SfRKkLqEOw8k_Z2cub8lebfTQ/viewform">Caso o seu problema não esteja listado acima, clique aqui para entrar em contato com o suporte técnico</a></h4>
    </div>
  </article>




