import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProfileComponent } from './components/display-info/profile/profile.component';
import { FollowerQuestionComponent } from './components/display-info/follower/follower-question.component';
import { FollowerCase1Component } from './components/display-info/follower/follower-case1/follower-case1.component';
import { FollowerCase2Component } from './components/display-info/follower/follower-case2/follower-case2.component';
import { FollowerCase3Component } from './components/display-info/follower/follower-case3/follower-case3.component';
import { FollowerCase4Component } from './components/display-info/follower/follower-case4/follower-case4.component';
import { FollowerCase5Component } from './components/display-info/follower/follower-case5/follower-case5.component';
import { FollowerCase6Component } from './components/display-info/follower/follower-case6/follower-case6.component';
import { FollowerCase7Component } from './components/display-info/follower/follower-case7/follower-case7.component';
import { FollowerCase8Component } from './components/display-info/follower/follower-case8/follower-case8.component';
import { FollowerCase9Component } from './components/display-info/follower/follower-case9/follower-case9.component';
import { InfluencerQuestionComponent } from './components/display-info/influencer/influencer-question.component';
import { InfluencerCase1Component } from './components/display-info/influencer/influencer-case1/influencer-case1.component';
import { InfluencerCase2Component } from './components/display-info/influencer/influencer-case2/influencer-case2.component';
import { InfluencerCase3Component } from './components/display-info/influencer/influencer-case3/influencer-case3.component';
import { InfluencerCase4Component } from './components/display-info/influencer/influencer-case4/influencer-case4.component';
import { InfluencerCase5Component } from './components/display-info/influencer/influencer-case5/influencer-case5.component';
import { InfluencerCase6Component } from './components/display-info/influencer/influencer-case6/influencer-case6.component';
import { InfluencerCase7Component } from './components/display-info/influencer/influencer-case7/influencer-case7.component';
import { InfluencerCase8Component } from './components/display-info/influencer/influencer-case8/influencer-case8.component';
import { InfluencerCase9Component } from './components/display-info/influencer/influencer-case9/influencer-case9.component';
import { InfluencerCase10Component } from './components/display-info/influencer/influencer-case10/influencer-case10.component';

const routes: Routes = [
  {path: '', component: ProfileComponent},
  {path: 'comprei-pelo-clozzy-e-preciso-de-ajuda', component: FollowerQuestionComponent},
  {path: 'clozzy-para-seguidores', component: FollowerCase1Component},
  {path: 'como-configurar-conta-de-seguidor', component: FollowerCase2Component},
  {path: 'como-acessar-o-conteudo-do-influencer', component: FollowerCase3Component},
  {path: 'vantagens-em-comprar-pelo-clozzy', component: FollowerCase4Component},
  {path: 'como-cancelar-a-assinatura', component: FollowerCase5Component},
  {path: 'cobranças-indevidas', component: FollowerCase6Component},
  {path: 'problemas-ao-realizar-a-assinatura', component: FollowerCase7Component},
  {path: 'como-solicitar-o-reembolso', component: FollowerCase8Component},
  {path: 'perguntas-frequentes-dos-seguidores', component: FollowerCase9Component},
  {path: 'vendo-conteudo-pelo-clozzy-e-preciso-de-ajuda',
  component: InfluencerQuestionComponent},
  {path: 'clozzy-para-criadores-de-conteudo', component:InfluencerCase1Component},
  {path: 'configurar-conta-de-criador-de-conteudo', component:InfluencerCase2Component},
  {path: 'vantagens-em-vender-pelo-clozzy', component:InfluencerCase3Component},
  {path: 'como-alterar-o-valor-dos-meus-planos-clozzy', component:InfluencerCase4Component},
  {path: 'quanto-custa-vender-pelo-clozzy', component:InfluencerCase5Component},
  {path: 'como-sacar-o-saldo-das-minhas-vendas', component:InfluencerCase6Component},
  {path: 'o-que-significa-cada-status-dos-pagamentos', component:InfluencerCase7Component},
  {path: 'chargeback-vs-reembolso-quais-as-diferencas', component:InfluencerCase8Component},
  {path: 'o-que-significa-cada-status-das-assinaturas', component:InfluencerCase9Component},
  {path: 'perguntas-frequentes-dos-criadores-de-conteudo', component:InfluencerCase10Component},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
