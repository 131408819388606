<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/comprei-pelo-clozzy-e-preciso-de-ajuda">Comprei conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Cobranças indevidas</span></p>
    <div>
      <section>
        <h2>Cobranças indevidas</h2>
        <h3 class="explanation">Como contestar uma cobrança indevida</h3>
        <p>As cobranças indevidas ocorrem no caso de o seguidor ter feito cadastro em uma plataforma antiga. Para contestar a cobrança, sugerimos que entre em contato através do formulário de suporte do Clozzy <a target="_blank" rel="external" href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit"><strong>CLICANDO AQUI</strong></a></p>
      </section>
    </div>
  </article>


