<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/vendo-conteudo-pelo-clozzy-e-preciso-de-ajuda">Vendo conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>O que significa cada status das assinaturas</span></p>
    <div>
      <section>
        <h2>O que significa cada status das assinaturas</h2>
        <h3 class="explanation">Entenda as diferenças de status das assinaturas dos seus assinantes.</h3>
        <p>As assinaturas têm diferentes status que auxiliam os criadores e membros  acompanhar o acesso as comunidades.</p>
        <p>Uma assinatura pode assumir os seguintes status:</p>
        <li><strong>Ativa</strong>: o pagamento da última renovação está em dia.</li>
        <li><strong>Cancelada</strong>: o usuário optou por cancelar a assinatura por ele mesmo.</li>
        <li><strong>Pendente:</strong>: o usuário pagou a assinatura e está aguardando aprovação do influencer</li>
        <li><strong>Expirada</strong>: o cliente perdeu a assinatura por deixar de pagar</li>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>    </div>
  </article>


