<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/comprei-pelo-clozzy-e-preciso-de-ajuda">Comprei conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>Como configurar sua conta</span></p>
    <div>
      <section>
        <h2>Como configurar sua conta</h2>
        <h3 class="explanation">Vamos deixar sua conta pronta pra uso!</h3>
        <p>Para criar sua conta, você precisa acessar o link do influencer que você escolheu assinar. Esse link pode estar listado nas redes sociais dele, como na bio do instagram ou twitter, bem como em grupos de whatsapp e telegram que o influencer possa ter.</p>
        <p>Clicando nesse link, você verá uma tela de cadastro e pagamento. Preencha o formulário e siga as instruções corretamente.</p>
        <p>Você verá que há uma tela para enviar um comprovante, e embaixo está uma chave PIX.</p>
        <p>Você deve transferir o valor correto da assinatura que você escolheu para essa chave PIX.</p>
        <p>Através do seu celular, tire um print do comprovante de pagamento e envie em anexo na tela de cadastro.</p>
        <p><strong>NÃO SÃO ACEITOS ARQUIVOS PDF</strong></p>
        <p>Você receberá um email de confirmação de cadastro assim que o influencer aprovar o seu comprovante</p>
        <p><strong>IMPORTANTE</strong>: o influencer aprova cada comprovante manualmente, então pode ser que haja demora na aprovação. Neste caso, solicitamos que entre em contato diretamente com o influencer através dos canais de comunicação que ele disponibiliza, seja instagram, twitter, telegram ou whatsapp.</p>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>
    </div>
  </article>


