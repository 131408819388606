import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-influencer-case8',
  templateUrl: './influencer-case8.component.html',
  styleUrls: ['../../../../style-services/style-cases.css']
})
export class InfluencerCase8Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
