import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-influencer-case6',
  templateUrl: './influencer-case6.component.html',
  styleUrls: ['../../../../style-services/style-cases.css']
})
export class InfluencerCase6Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
