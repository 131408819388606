<article>
    <p class="map"><span><a class="routing" href=''>Todas as perguntas</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span><a class="routing" href="/comprei-pelo-clozzy-e-preciso-de-ajuda">Comprei conteúdo pelo Clozzy e preciso de ajuda</a> <i class="material-icons v-middle">arrow_forward_ios</i></span> <span>O que é o Clozzy e como ele funciona</span></p>
    <div>
      <section>
        <h2>O que é o Clozzy e como ele funciona</h2>
        <h3 class="explanation">Conheça a melhor plataforma de conteúdo exclusivo do Brasil</h3>
        <p>O Clozzy é uma plataforma de entrega de conteúdo, onde você pode acessar o conteúdo do seu influencer de forma discreta, rápida e exclusiva.</p>
        <p>Com o Clozzy, você pode ficar mais perto do seu influencer favorito. Você pode ver o conteúdo que a maioria das pessoas não vê.</p>
        <p>Você pode assinar o conteúdo de vários influencers ao mesmo tempo, seja de qual área eles forem. Basta que o seu influencer tenha uma conta no Clozzy, e através do link de acesso, você pode consumir o conteúdo exclusivo dele.</p>
        <p>Cada influencer estipula o valor de sua assinatura. Você pode pagar essa assinatura por PIX com assinatura mensal, trimestral ou semestral para que o acesso continue ativo.</p>
        <p>Os influencers dão opção de assinatura mensal, trimestral e semestral. Você pode assinar o plano que quiser, tendo a opção de se preocupar ou não com a renovação da assinatura.</p>
      </section>
      <h4><a href="https://docs.google.com/forms/d/1ODDlwXQQc_3DwS37JhQeCuqp3mC-KBMeAoSwiiwu-wk/edit">Caso o seu problema não esteja listado nas ocasiões acima, clique aqui para acessar o formulário de suporte.</a></h4>    </div>
  </article>


